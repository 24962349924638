export class FetchError extends Error {
  public status: number
  public response: Response
  public originalError?: Error

  constructor(
    message: string,
    status: number,
    response: Response,
    originalError?: Error
  ) {
    super(message) // Pass the message to the Error constructor
    this.status = status // Add a status property
    this.name = this.constructor.name // Set the error name to the class name
    this.response = response
    this.originalError = originalError
  }
}
