import { ErrorText } from "@tokenterminal/ui/ErrorText"
import { Heading } from "@tokenterminal/ui/Heading"
import { Stack } from "@tokenterminal/ui/Stack"
import Head from "next/head"
import { Component, type ReactNode } from "react"

export class AppErrorBoundary extends Component<{ children: ReactNode }> {
  state = {
    hasError: false,
    errorMsg: "",
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, erroMsg: error?.toString() ?? "" }
  }

  // componentDidCatch(error, errorInfo) {
  // we can pipe this to sentry or other logger if we want
  // }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <Head>
            <title>Token Terminal | Fundamentals for crypto</title>
            <link rel="shortcut icon" href="/favicon.ico" />
          </Head>
          <Stack alignItems="center" justifyContent="center" height="100vh">
            <Heading color="error">Error</Heading>
            <ErrorText as="span">{this.state.errorMsg}</ErrorText>
          </Stack>
        </>
      )
    }

    return this.props.children
  }
}
