import { assignInlineVars } from '@vanilla-extract/dynamic'
import {
  forwardRef,
  type HTMLAttributes,
  type LabelHTMLAttributes,
} from 'react'
import { Box, type BaseBoxProps, type BoxProps } from '../Box'
import {
  withNoLinesCss,
  noLines,
  primaryTextCss,
  secondaryTextCss,
  baseColorTextCss,
  textXsCss,
  baseTextCss,
  textSCss,
  textMCss,
  failureTextCss,
  successTextCss,
  textXxsCss,
  textLCss,
} from './Text.css'

// All supported Box props
type TextBoxProps = Omit<
  BaseBoxProps,
  'size' | 'as' | 'onClick' | 'fontSize' | 'htmlFor'
> & {
  variant?: 'primary' | 'secondary'
}

// All supported react props + re-mapped size
type BaseProps = TextBoxProps &
  Omit<HTMLAttributes<HTMLParagraphElement>, keyof TextBoxProps> & {
    as?: keyof Pick<
      JSX.IntrinsicElements,
      | 'p'
      | 'span'
      | 'div'
      | 'h1'
      | 'h2'
      | 'h3'
      | 'h4'
      | 'code'
      | 'figcaption'
      | 'button'
      | 'label'
      | 'legend'
    >
    size?: Exclude<BaseBoxProps['fontSize'], 'xxs'>
    noOfLines?: string | number
  }

// If this is still problematic, just use AllHTMLAttributes. These are necessary for eg. htmlFor when used as a label
type LabelTextProps = Omit<
  LabelHTMLAttributes<HTMLLabelElement>,
  keyof BaseProps
>

export type TextProps = Partial<LabelTextProps> & BaseProps

export const Text = forwardRef<HTMLElement, TextProps>(function (
  {
    children,
    as = 'p',
    size = 'm',
    noOfLines,
    className,
    style,
    variant,
    ...props
  },
  ref,
) {
  return (
    // @ts-ignore
    <Box
      as={as}
      fontSize={size}
      lineHeight={size}
      ref={ref}
      style={{
        ...style,
        ...(noOfLines && assignInlineVars({ [`${noLines}`]: `${noOfLines}` })),
      }}
      className={[
        className,
        {
          [withNoLinesCss]: !!noOfLines,
          [primaryTextCss]: variant === 'primary',
          [secondaryTextCss]: variant === 'secondary',
        },
      ]}
      {...props}
    >
      {children}
    </Box>
  )
})

type Variants =
  | 'primary'
  | 'secondary'
  | 'highlight'
  | 'success'
  | 'failure'
  | 'base'
  | null
export type TextWithCapsizeProps = {
  children: React.ReactNode
  size?: Extract<
    BoxProps['fontSize'],
    'xxs' | 'xs' | 's' | 'm' | '10' | '12' | '14' | '16' | '20' | '24'
  >
  variant?: Variants
} & Omit<BoxProps, 'fontSize' | 'size'>

export function TextWithCapsize({
  children,
  as = 'span',
  size = 'xs',
  variant = 'primary',
  className,
  ...props
}: TextWithCapsizeProps) {
  return (
    <Box
      as={as}
      className={[
        baseTextCss,
        {
          [primaryTextCss]: variant === 'primary' || variant === 'highlight',
          [secondaryTextCss]: variant === 'secondary',
          [successTextCss]: variant === 'success',
          [failureTextCss]: variant === 'failure',
          [failureTextCss]: variant === 'failure',
          [baseColorTextCss]: variant === 'base',
          [textXxsCss]: size === 'xxs' || size === '10',
          [textXsCss]: size === 'xs' || size === '12',
          [textSCss]: size === 's' || size === '14',
          [textMCss]: size === 'm' || size === '16',
          [textLCss]: size === '24',
        },
        className,
      ]}
      {...props}
    >
      {children}
    </Box>
  )
}
