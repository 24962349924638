// import * as Sentry from "@sentry/nextjs"
import { type Fetcher } from "./create-fetcher"
import { FetchError } from "./fetch-error"

export type EndpointsGET = Parameters<Fetcher<"get">>[1]

export async function apiGET<T extends EndpointsGET>(endpoint: T) {
  const res = await globalThis.fetchApi("get", endpoint)

  if (!res.ok) {
    // Error for debugging
    const fetchError = new FetchError(res.statusText, res.status, res)
    const error = new Error(
      `Failed to fetch from "${endpoint}" - ${res.statusText}`
    )
    console.error(error)
    // Sentry.captureException(error, {
    //   extra: {
    //     endpoint: endpoint,
    //   },
    // })

    throw fetchError
  }

  const resData = await res.json()

  return resData
}
