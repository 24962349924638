// @ts-check
/** @typedef {"bloomberg" | "tt" | undefined} App */

export const TT_DEFAULT_JWT = process.env.NEXT_PUBLIC_FRONTEND_JWT

export const NOTION_INTEGRATION_API_TOKEN =
  process.env.NOTION_INTEGRATION_API_TOKEN

export const APPLICATION = /** @type { App } */ (
  process.env.NEXT_PUBLIC_APPLICATION
)

// We should update this for each change in the privacy policy, users need to re-consent.
export const CONSENT_VERSION = 1
export const VERSION_TERMS_OF_SERVICE = "2023-11-07"
export const VERSION_PRIVACY_POLICY = "2023-10-27"

export const TERMS_OF_SERVICE_VERSION = "2023-11-07"
export const PRIVACY_POLICY_VERSION = "2023-10-27"

export const STRIPE_TOKEN =
  process.env.NEXT_PUBLIC_STRIPE_TOKEN ||
  "pk_live_51Hj3MjENIA9dlSUdxEef1IZMAauFVaOvFYAmv95OxMSlqouICRrMNIsxnXvUeLcnMN8wsWrve7jBP8DNF8rYEEk1000rl0wn2Q"

/** @type {string} */
// @ts-ignore
export const APP_VERSION = global.__APP_VERSION__ ?? "DEV"

export const OAUTH_BLOOMBERG_CLIENT_ID =
  process.env.NEXT_PUBLIC_OAUTH_BLOOMBERG_CLIENT_ID
export const OAUTH_BLOOMBERG_CLIENT_SECRET =
  process.env.OAUTH_BLOOMBERG_CLIENT_SECRET
export const OAUTH_GOOGLE_CLIENT_ID =
  process.env.NEXT_PUBLIC_OAUTH_GOOGLE_CLIENT_ID || ""
export const OAUTH_GOOGLE_CLIENT_SECRET =
  process.env.OAUTH_GOOGLE_CLIENT_SECRET || ""
export const OAUTH_GOOGLE_CLIENT_CALLBACK =
  process.env.OAUTH_GOOGLE_CLIENT_CALLBACK ||
  "https://tokenterminal.com/api/auth/google"

export const BASE_PATH = ""
