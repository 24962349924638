export function getCookie(cookieName: string): string | null {
  if (typeof document === `undefined`) {
    return null
  }

  const ck = document.cookie
  const cookieIndex = ck.indexOf(`${cookieName}=`)

  // cookie not found
  if (cookieIndex === -1) {
    return null
  }

  // do an extra check to make sure the cookie name is not a substring of another cookie name
  if (cookieIndex !== 0 && ck.charAt(cookieIndex - 1) !== ` `) {
    return null
  }

  const cookieValueStart = cookieIndex + cookieName.length + 1
  const cookieValueEnd = ck.indexOf(`;`, cookieValueStart)
  const cookieValue = ck.substring(
    cookieValueStart,
    cookieValueEnd === -1 ? ck.length : cookieValueEnd
  )

  return cookieValue
}
