import { getCookie } from "../utils/cookies/get-cookie"
import type {
  InferRequestsByMethod,
  SupportedMethods,
} from "@tokenterminal/tt-analytics-api-types/dist/api-routes"

const ID_TOKEN_COOKIE = "_idtoken"

export type CustomRequestInit<
  Methods extends SupportedMethods,
  Paths extends
    keyof InferRequestsByMethod<Methods> = keyof InferRequestsByMethod<Methods>,
> = Omit<RequestInit, "method" | "body"> & {
  body?: Methods extends "get"
    ? never
    : // @ts-ignore is necessary because typescript doesn't know that our Methods and paths is a tuple
      InferRequestsByMethod<Methods>[Paths][1]
}

export type CustomResponse<
  Methods extends SupportedMethods,
  Paths extends
    keyof InferRequestsByMethod<Methods> = keyof InferRequestsByMethod<Methods>,
> = Omit<Response, "json"> & {
  // @ts-ignore is necessary because typescript doesn't know that our Methods and paths is a tuple
  json: () => Promise<InferRequestsByMethod<Methods>[Paths][0]>
}

export type GetResponse<T extends keyof InferRequestsByMethod<"get">> =
  CustomResponse<"get", T>

export type GetResponseData<T extends keyof InferRequestsByMethod<"get">> =
  InferRequestsByMethod<"get">[T][0]

function appendApiOrigin(url: string, apiUrl: string): string {
  const parsedUrl = new URL(apiUrl)

  let path = new URL(
    (parsedUrl.pathname + url).replace(/\/\//g, "/"),
    parsedUrl.origin
  ).toString()

  if (path.includes("/bloomberg/v2/")) {
    path = path.replace("/bloomberg/v2/", "/bloomberg/v1/")
  }

  return path
}

function hasLoginCookie(): boolean {
  return !!getCookie(ID_TOKEN_COOKIE)
}

export type Fetcher<
  Methods extends SupportedMethods,
  Paths extends
    keyof InferRequestsByMethod<Methods> = keyof InferRequestsByMethod<Methods>,
> = (
  method: Methods,
  path: Paths,
  ...args: Methods extends "get"
    ? // we don't need request options if it's get
      [CustomRequestInit<Methods, Paths>?]
    : [CustomRequestInit<Methods, Paths>]
) => Promise<CustomResponse<Methods, Paths>>

export function createFetcher<
  Methods extends SupportedMethods = "get",
  Paths extends
    keyof InferRequestsByMethod<Methods> = keyof InferRequestsByMethod<Methods>,
>({
  origin,
  rateLimitJwt,
  appVersion,
  defaultApiToken,
}: {
  origin: string
  defaultApiToken?: string
  rateLimitJwt?: string
  appVersion?: string
}): Fetcher<Methods, Paths> {
  const tst: Fetcher<Methods, Paths> = function fetchApi(
    method: Methods = "get" as Methods,
    path: Paths,
    ...args: Methods extends "get"
      ? // we don't need request options if it's get
        [CustomRequestInit<Methods, Paths>?]
      : [CustomRequestInit<Methods, Paths>]
  ): Promise<CustomResponse<Methods, Paths>> {
    const {
      body,
      headers: givenHeaders,
      ...options
    } = (args.length ? args[0] : {}) as CustomRequestInit<Methods, Paths>

    const headers =
      givenHeaders instanceof Headers ? givenHeaders : new Headers()

    if (rateLimitJwt) {
      headers.append("x-tt-terminal-jwt", rateLimitJwt)
    }

    if (appVersion) {
      headers.append("x-app-version", appVersion)
    }

    if (defaultApiToken && !hasLoginCookie()) {
      headers.append("Authorization", `Bearer ${defaultApiToken}`)
    }

    if (typeof window !== "undefined") {
      headers.append("x-app-path", window.location.pathname)
    }

    const newPath: string = path as string

    const requestOptions: RequestInit = {
      method,
      credentials: "include", // By default always send cookies for auth
      headers,
      ...options,
    }

    if (body instanceof FormData) {
      requestOptions.body = body
    } else {
      requestOptions.body = body ? JSON.stringify(body) : undefined
    }

    const request = new Request(
      appendApiOrigin(newPath, origin),
      requestOptions
    )
    return fetch(request) as Promise<CustomResponse<Methods, Paths>>
  }

  return tst
}
