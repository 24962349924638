import clsx from 'clsx'
import {
  highlightColorAtom,
  altHighlightColorAtom,
  primaryBackgroundColorAtom,
  primaryColorAtom,
  secondaryBackgroundColorAtom,
  secondaryColorAtom,
  errorColorAtom,
  warningColorAtom,
  invertedBackgroundColorAtom,
  invertedColorAtom,
  neutralColorAtom,
  highlightBackgroundColorAtom,
  successColorAtom,
  bodyBackgroundAtom,
  inheritBackgroundColorAtom,
  invertedHighlightBackgroundColorAtom,
  invertedHighlightColorAtom,
} from './atoms.colors.css'
import * as resetStyles from './reset.css'
import { sprinkles } from './sprinkles.css'
// eslint-disable-next-line import-x/order
import { bleedAtoms } from './bleed.css'

// Colors are special as we have light/dark theme.

type Sprinkles = Parameters<typeof sprinkles>[0]
type Bleeds = Parameters<typeof bleedAtoms>[0]

export interface Atoms extends Omit<Sprinkles, 'color' | 'background'> {
  reset?: keyof JSX.IntrinsicElements
  bleedX?: Bleeds['marginX']
  bleedY?: Bleeds['marginY']
  background?:
    | Sprinkles['background']
    | 'primary'
    | 'secondary'
    | 'invertedHighlight'
    | 'highlight'
    | 'inverted'
    | 'body'
    | 'inherit'
  color?:
    | Sprinkles['color']
    | 'primary'
    | 'secondary'
    | 'inverted'
    | 'highlight'
    | 'invertedHighlight'
    | 'altHighlight'
    | 'success'
    | 'error'
    | 'warning'
    | 'neutral'
}

export const atoms = ({
  reset,
  bleedX,
  bleedY,
  background,
  color,
  ...rest
}: Atoms) => {
  const sprinklesClasses = sprinkles(rest)
  const bleedClasses = bleedAtoms({ marginX: bleedX, marginY: bleedY })
  let elementReset = null

  if (reset) {
    elementReset = [
      resetStyles.base,
      resetStyles.element[reset as keyof typeof resetStyles.element],
    ]
  }

  let colorSprinkles: string | undefined
  try {
    if (color) {
      // @ts-ignore
      colorSprinkles = sprinkles({ color })
    }
  } catch (e) {}

  let backgroundSprinkles: string | undefined
  try {
    if (background) {
      // @ts-ignore
      backgroundSprinkles = sprinkles({ background })
    }
  } catch (e) {}

  return clsx(
    elementReset,
    sprinklesClasses,
    bleedClasses,
    colorSprinkles,
    backgroundSprinkles,
    {
      [inheritBackgroundColorAtom]: background === 'inherit',
      [primaryBackgroundColorAtom]: background === 'primary',
      [invertedBackgroundColorAtom]: background === 'inverted',
      [secondaryBackgroundColorAtom]: background === 'secondary',
      [highlightBackgroundColorAtom]: background === 'highlight',
      [invertedHighlightBackgroundColorAtom]:
        background === 'invertedHighlight',
      [bodyBackgroundAtom]: background === 'body',
      [neutralColorAtom]: color === 'neutral',
      [primaryColorAtom]: color === 'primary',
      [invertedColorAtom]: color === 'inverted',
      [secondaryColorAtom]: color === 'secondary',
      [highlightColorAtom]: color === 'highlight',
      [invertedHighlightColorAtom]: color === 'invertedHighlight',
      [altHighlightColorAtom]: color === 'altHighlight',
      [successColorAtom]: color === 'success',
      [errorColorAtom]: color === 'error',
      [warningColorAtom]: color === 'warning',
    },
  )
}
