import { type ReactNode } from 'react'
import { Text, type TextProps } from './Text'

export function ErrorText(
  props: {
    children: ReactNode
  } & Omit<TextProps, 'color'>,
) {
  return <Text color="error" {...props} />
}
