import { type GETRequests } from "@tokenterminal/tt-analytics-api-types/dist/api-routes"
import { type WritableAtom } from "jotai"
import { atomFamily, atomWithRefresh } from "jotai/utils"
import { type Result } from "../../utils/jotai/unwrap"
import { apiGET, type EndpointsGET } from "../api-get"
import { type FetchError } from "../fetch-error"

const fetchAtomWithRefreshAtom = atomFamily((endpoint: EndpointsGET) => {
  const refreshAtom = atomWithRefresh(async () => {
    try {
      const res = await apiGET(endpoint)

      return {
        ok: res,
        err: null,
      }
    } catch (err) {
      return {
        ok: null,
        err: err as FetchError,
      }
    }
  })

  refreshAtom.debugLabel = `fetchAtomWithRefresh(${endpoint})`

  return refreshAtom
})

export function fetchAtomWithRefresh<T extends EndpointsGET>(
  endpoint: T
): WritableAtom<Promise<Result<GETRequests[T][0], FetchError>>, [], void> {
  return fetchAtomWithRefreshAtom(endpoint) as WritableAtom<
    Promise<Result<GETRequests[T][0], FetchError>>,
    [],
    void
  >
}
