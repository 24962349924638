import "@/components/layout/main.css"

import { createFetcher } from "@tokenterminal/core/api"
import { GeistMono, GeistSans, FKGroteskLanding } from "@tokenterminal/ui/Fonts"
import { type NextComponentType, type NextPageContext } from "next"
import { type AppProps as NextAppProps } from "next/app"
import Head from "next/head"
import {
  memo,
  type ReactElement,
  type ComponentType,
  Fragment,
  useRef,
} from "react"
import { AppErrorBoundary } from "@/components/AppErrorBoundary"
import { GTM } from "@/components/gtm"
import { APP_VERSION, TT_DEFAULT_JWT } from "@/constants/app.mjs"

function noop(font: string) {}

type NextPageWithLayout = NextComponentType<NextPageContext, any, any> & {
  getLayout?: (page: ReactElement, args: unknown) => ReactElement
  v3?: boolean
}

interface AppProps {}

interface Props extends NextAppProps {
  pageProps: AppProps
}

interface InnerProps extends Omit<Props, "pageProps"> {
  pageProps: AppProps
  Component: NextPageWithLayout
}

function App(props: InnerProps): ReactElement {
  const { Component, pageProps } = props

  if (Component.v3) {
    if (Component.getLayout) {
      return Component.getLayout(<Component {...pageProps} />, {})
    }

    return <Component {...pageProps} />
  }

  // https://nextjs.org/docs/basic-features/layouts
  if (Component.getLayout) {
    return Component.getLayout(<Component {...pageProps} />, pageProps)
  }

  return <Component {...pageProps} />
}

const AppHead = memo(
  function AppHead() {
    return (
      <Head>
        <link rel="preconnect" href="https://api.tokenterminal.com" />

        <title>Token Terminal | Fundamentals for crypto</title>

        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=no"
        />
        <meta
          name="facebook-domain-verification"
          content="141k7s1zlavs1vznks3nonb513g4f1"
        />
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Measure and evaluate Blockchains and Dapps through traditional financial metrics. Token Terminal is a Crypto Analytics Platform with Advanced Metrics & Tools."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@tokenterminal" />
        <meta
          name="twitter:title"
          content="Token Terminal"
          key="twitter:title"
        />
        <meta
          name="twitter:description"
          key="twitter:description"
          content="Fundamentals for crypto."
        />
        <meta
          name="twitter:image"
          content="https://tokenterminal.com/thumbnail.png"
        />
        <meta
          property="og:url"
          key="og:url"
          content="https://tokenterminal.com"
        />
        <meta
          property="og:image"
          content="https://tokenterminal.com/thumbnail.png"
        />
        <link rel="shortcut icon" href="/favicon.ico" />
      </Head>
    )
  },
  () => true
)

// Fix for local development - SSRProvider doesn't seem to work correctly
// Need to check if SSRProvider
const Provider = Fragment

// No need to use react context for this. Just use global variable
global.isServerOrFirstHydration = true

global.fetchApi = createFetcher({
  origin: globalThis?.origin?.endsWith(".tokenterminal.dev")
    ? "https://api.tokenterminal.dev"
    : "https://api.tokenterminal.com",
  appVersion: APP_VERSION,
  rateLimitJwt: TT_DEFAULT_JWT,
  defaultApiToken: process.env.NEXT_PUBLIC_API_DEFAULT_TOKEN,
})

const GTM_ID = "GTM-W3K5VQF"
const INITIAL_GTM_VALUES = { app: "landing", version: APP_VERSION }

function withContext(Component: ComponentType<InnerProps>) {
  noop(GeistSans.variable)
  noop(GeistMono.variable)
  noop(FKGroteskLanding.variable)

  return function AppWithContext({ pageProps, ...props }: Props) {
    const renderCount = useRef(0)
    // A render means the page/route changed
    if (global.isServerOrFirstHydration && renderCount.current++ > 0) {
      global.isServerOrFirstHydration = false
    }

    return (
      <Provider>
        <AppHead />
        <GTM id={GTM_ID} initialValues={INITIAL_GTM_VALUES} />
        <AppErrorBoundary>
          <Component {...props} pageProps={pageProps} />
        </AppErrorBoundary>
      </Provider>
    )
  }
}

export default withContext(App)
